import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HelpContactService } from '@app/shared/services/help-contact.service';
import { HelpContactForm } from './models/help-contact-form';
import { AlertService } from 'services/alert-service/alert.service';
import { capitalize } from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-help-contact-form',
  templateUrl: './help-contact-form.component.html',
  styleUrls: ['./help-contact-form.component.scss'],
  providers: [HelpContactService],
  standalone: false,
})
export class HelpContactFormComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<HelpContactFormComponent>,
    private readonly _helpContactService: HelpContactService,
    private readonly _alertService: AlertService
  ) {}

  contactFormGroup = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    phone: new FormControl<string>('', Validators.required),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    company: new FormControl<string>('', Validators.required),
    location: new FormControl<string>('', Validators.required),
    message: new FormControl<string>('', Validators.required),
  });

  ngOnInit(): void {}
  onSubmit() {
    if (this.contactFormGroup.valid) {
      let d = this.contactFormGroup.value as HelpContactForm;
      this._helpContactService
        .sendMail(this.contactFormGroup.value as HelpContactForm)
        .subscribe((e) => {
          this._alertService.success('The message was sent');
          this.dialogRef.close();
        });
    } else {
      (
        Object.keys(this.contactFormGroup.controls) as Array<
          keyof typeof this.contactFormGroup.controls
        >
      ).forEach((e, b) => {
        let control = this.contactFormGroup.controls[e];
        if (!control.valid) {
          if (control.errors?.['required']) {
            this._alertService.error(`The field ${capitalize(e)} is required`);
          }
          if (control.errors?.['email']) {
            this._alertService.error(
              `Wrong pattern for ${capitalize(e)} field`
            );
          }
        }
      });
    }
  }
}
