import { Component, OnInit } from '@angular/core';
import { Dictionary } from '@app/core/models/dictionary.model';
import { FeatureFlagEnum } from '@app/shared/FeatureFlags/feature-flag.enum';
import { FeatureFlagService } from '@app/shared/FeatureFlags/services/feature-flag.service';
import { AccountsService } from '@app/shared/services/accounts.service';
import { BarSelectorService } from '@app/shared/services/bar-selector.service';
import { DivisionsApiService } from '@app/shared/services/divisions-api.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-division-selector',
    templateUrl: './division-selector.component.html',
    styleUrls: ['./division-selector.component.scss'],
    providers: [AccountsService, DivisionsApiService, FeatureFlagService],
    standalone: false
})
export class DivisionSelectorComponent implements OnInit {
  public divisions: Dictionary[] = [];

  public selectedDivision: Dictionary = BarSelectorService.divisionDefault;
  private ngFeatureFlagSubscription = new Subject<void>();
  constructor(
    private accountsService: AccountsService,
    private divisionsApiService: DivisionsApiService,
    private divProgSelectService: BarSelectorService,
    
  ) {}

  ngOnInit(): void {
    
    let  method = this.divisionsApiService.getByLoggedUser();
        method.subscribe((result) => {
          this.divisions = [BarSelectorService.divisionDefault, ...result];

          this.divProgSelectService.subscribe((data) => {
            this.selectedDivision = data.division;
          });
        });
    
  }
  ngOnDestroy(){
    this.ngFeatureFlagSubscription.next();
    this.ngFeatureFlagSubscription.complete();
  }

  selectDivision(id: number) {
    this.selectedDivision = this.divisions.filter((x) => x.id === id)[0];

    this.divProgSelectService.nextValue(
      this.selectedDivision,
      BarSelectorService.programDefault,
      BarSelectorService.subProgramDefault
    );
  }
}
