import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from 'app/core/models/dictionary.model';
import { BaseWebService } from 'app/core/services/base-web.service';
import { CustomSelectOption } from '../components/input/custom-select/models/custom-select-option';

@Injectable()
export class DivisionsService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('makorsource/divisions', http);
  }

  getProgramsDictionary(divisionId: number) {
    return this.getMethod<CustomSelectOption[]>(`${divisionId}/programs`);
  }
  getMultipleProgramsDictionary(url: string) {
    return this.getMethod<Dictionary[]>(`programs/${url}`);
  }
 
  getByAccount(accountId: number) {
    return this.getMethod<Dictionary[]>(`getByAccountId/${accountId}`);
  }
}
