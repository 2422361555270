import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { uniqBy } from 'lodash';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { FileDocument } from './models/file-document.model';

@Component({
  selector: 'app-file-upload-documents',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [],
  standalone: false,
})
export class UploadFilesComponent implements OnInit {
  protected documents: FileDocument[] = [];
  protected formInputs: { name: string; fControl: FormControl }[] = [];

  @Input() disabledMode: boolean = false;
  @Input() addDescription: boolean = false;
  @Output() downloadEvent = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  getUploadedFiles() {
    this.documents.forEach(
      (x) =>
        (x.description = this.formInputs.find(
          (y) => y.name == x.name
        )?.fControl.value)
    );
    return this.documents;
  }

  showDocuments(documents: FileDocument[]) {
    this.documents = documents;

    this.formInputs = [];
    documents.forEach((x) => {
      this.formInputs.push({
        name: x.name,
        fControl: new FormControl(x.description),
      });
    });
  }

  protected downloadDoc(idFile: number) {
    this.downloadEvent.emit(idFile);
  }

  protected upload(e: FileList) {
    const fileListAsArray: any[] = Array.from(e);
    fileListAsArray.forEach((item, i) => {
      this.documents.push(item);
    });
    let fileArr = uniqBy(this.documents, (e) => {
      return e.name;
    }).map((item) => item);

    this.documents = fileArr;
    this.addInputFormControl();
  }

  protected deleteDocument(fileName: string) {
    this.documents = this.documents.filter((x) => x.name !== fileName);
    this.dellInputFormControl(fileName);
  }

  protected reloadDocuments(documents: FileDocument[]) {
    this.documents = documents;
  }

  protected getFormControl(fileName: string): FormControl {
    return this.formInputs.find((x) => x.name == fileName)
      ?.fControl as FormControl;
  }

  private addInputFormControl() {
    let formNames = this.formInputs.map((x) => x.name);
    let toAdd = this.documents
      .filter((x) => !formNames.includes(x.name))
      .map((x) => x.name);
    toAdd.forEach((x) =>
      this.formInputs.push({ name: x, fControl: new FormControl() })
    );
  }

  private dellInputFormControl(fileName: string) {
    let index = this.formInputs.map((x) => x.name).indexOf(fileName);
    this.formInputs.splice(index, 1);
  }
}
