import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
  standalone: false,
})
export class ContentHeaderComponent implements OnInit, AfterViewInit {
  @Input() header: string = '';
  @Input() color: string = '';
  @Input() withSearch: boolean = false;

  @ViewChild('underline') underline: ElementRef;

  @Output() onSearch = new EventEmitter<string>();

  searchFormControl: FormControl = new FormControl({
    value: '',
    disabled: false,
  });

  constructor() {}
  ngAfterViewInit(): void {
    this.underline.nativeElement.setAttribute(
      'style',
      `background: ${this.color}`
    );
  }
  searchSubmit() {
    this.onSearch.emit(this.searchFormControl.value);
  }
  ngOnInit(): void {}
}
