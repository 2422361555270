import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from 'app/core/models/dictionary.model';
import { BaseWebService } from 'app/core/services/base-web.service';

@Injectable()
export class AccountsService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('makorsource/accounts', http);
  }


  GetIdByName(name : string){
    return this.getMethod<number | null>(`idByName/${name}`);
  }
  
  getAccountName(){
    return this.getMethod<Dictionary>(`accountName`);
  }
}
