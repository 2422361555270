import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseWebService } from 'app/core/services/base-web.service';
import { AccountsUser } from '@app/modules/accounts/models/accounts-user.model';
import { PagedListParams } from '@app/core/models/paged-list-params.model';
import { UserUpdate } from '@app/modules/accounts/models/user-update.model';
import { UserCreate } from '@app/modules/accounts/models/user-create.model';
import { MailRegistrationRequest } from '../models/mail-registration-request';
import { Observable } from 'rxjs';
import { UserImportResult } from '@app/modules/accounts/models/user-import-result';
import { UserImportInfo } from '@app/modules/accounts/models/user-import-info';
import { UserAccount } from '../models/user-account';
import { AccountDivision } from '../models/account-division';
import { AccountDivision as AccountDivisionCopyP } from '../models/copy-permission.model';
import { UserReadDTO } from '@app/dtos/user-read-dto';

@Injectable()
export class UsersService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('identity/users', http);
  }

  getUsersPagedList(usersListParams: PagedListParams) {
    return this.getPagedListMethod<AccountsUser, PagedListParams>(
      'AccountUsers',
      usersListParams
    );
  }

  sendMailRegistration(request: MailRegistrationRequest) {
    return this.postMethod(`SendMailRegistration`, request);
  }
  getUser(userId: string) {
    return this.getMethod<UserReadDTO>(userId);
  }
  getIdByLogin(login: string) {
    return this.getMethod<string>(`IdByLogin/${login}`);
  }
  getMyAccountInfo() {
    return this.getMethod<UserReadDTO>('MyAccountInfo');
  }

  updateProfile(userDto: UserUpdate) {
    return this.postMethod<UserUpdate>('UpdateProfile', userDto);
  }

  createUser(userDto: UserCreate) {
    return this.postMethod<string>('Create', userDto);
  }

  updateUser(userDto: UserCreate) {
    return this.postMethod<UserCreate>('Update', userDto);
  }

  switchAccount(idAccount: number) {
    return this.postMethod<UserCreate>(`SwitchAccount/${idAccount}`, null);
  }

  importUsers(formData: FormData): Observable<UserImportResult> {
    return this.postMethodNoHeaders('ImportUsers', formData);
  }

  getMakorAccountIds() {
    return this.getMethod<number[]>(`GetUserMakorAccountIds`);
  }

  getImportInfoPagedList(listParams: PagedListParams) {
    return this.getPagedListMethod<UserImportInfo, PagedListParams>(
      `PagedUserImportInfos`,
      listParams
    );
  }

  addUpdateAccount(userId: string, account: UserAccount) {
    return this.postMethod<number>(`AddUpdateAccount/${userId}`, account);
  }

  deleteAccount(id: number) {
    return this.deleteMethod(`DeleteAccount/${id}`);
  }

  addUpdateDivisionProgram(
    accountId: number,
    accountDivision: AccountDivision
  ) {
    return this.postMethod<UserAccount[]>(
      `AddUpdateDivisionProgram/${accountId}`,
      accountDivision
    );
  }

  createDivisionsProgramStructure(
    accountId: number,
    accountDivision: AccountDivision
  ) {
    return this.postMethod<AccountDivisionCopyP[]>(
      `CreateDivisionsProgramStructure/${accountId}`,
      accountDivision
    );
  }

  createUpdateDivisionProgram(
    accountId: number,
    accountDivision: AccountDivisionCopyP[]
  ) {
    return this.postMethod<UserAccount[]>(
      `CreateUpdateDivisionProgram/${accountId}`,
      accountDivision
    );
  }

  clearDivisions(userAccountId: number) {
    return this.postMethod(`ClearDivisions/${userAccountId}`);
  }

  deleteDivisionProgram(id: number) {
    return this.deleteMethod(`DeleteDivisionProgram/${id}`);
  }
  getAccountIdByLoginAndMakorAccountId(login: string, makorAccountId: number) {
    return this.getMethod<number | null>(
      `AccountIdByLoginAndMakorAccountId/${login}/${makorAccountId}`
    );
  }

  getUsersReport() {
    return this.getMethodFile('GetUsersReport');
  }

  getLocales() {
    return [
      { value: 'en-US', text: 'en-US' },
      { value: 'en-GB', text: 'en-GB' },
      { value: 'de-DE', text: 'de-DE' },
      { value: 'pl-PL', text: 'pl-PL' },
    ];
  }
}
