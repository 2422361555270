import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileDocument } from '../models/file-document.model';

@Component({
  selector: 'app-uploaded-files-panel',
  templateUrl: './uploaded-files-panel.component.html',
  styleUrl: './uploaded-files-panel.component.scss',
  standalone: false,
})
export class UploadedFilesPanelComponent {
  @Input() files: FileDocument[] = [];
  @Input() hideDelete = false;

  @Output() downloadFileEvent = new EventEmitter<number>();
  @Output() deleteFileEvent = new EventEmitter<FileDocument>();

  protected downloadFile(idFile: number) {
    this.downloadFileEvent.emit(idFile);
  }

  protected deleteFile(file: FileDocument) {
    this.deleteFileEvent.emit(file);
  }
}
