<div class="uploaded-files-panel" *ngIf="files && files.length > 0">
  <span>Uploaded files</span>
  <div class="file-list">
    <div class="file text-primary" *ngFor="let file of files; let i = index">
      <span class="btn-download" (click)="downloadFile(file.id)">{{
        file.name
      }}</span>
      <div *ngIf="!hideDelete" class="file-options">
        <button mat-mini-fab color="primary" (click)="deleteFile(file)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
