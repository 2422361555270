import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { PageSettings } from 'app/core/models/page-settings';
import { ColumnSettings } from './column-settings';
import {
  CheckBoxColumnDef,
  Column,
} from './components/column-container/column';
import { Header } from './components/column-header/header';
import { SortType } from './components/column-header/sort-type';
import { Paginator } from './paginator';
import { Sort } from './sort';
import { TableContext } from './table-context';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  providers: [TableContext],
  standalone: false,
})
export class CustomTableComponent implements OnInit {
  protected loading: boolean = false;

  @Input() idTable: number;
  @Input() titleCustomizeTable: string = 'Customize table';
  @Input({ required: true }) columnDefinitions: Column[] = [];
  @Input() data: any[];
  protected dataDisplay: any[];
  @Input() useTableContextFunction: boolean = false;
  @Input({ required: true }) settings: PageSettings;
  @Input() hiddePaginator: boolean = false;
  @Input() checkBoxs: boolean = false;
  @Input() isTableMark: boolean = false;
  @Input() isRowToMark: ((params: any) => boolean) | null = null;

  @Output() onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() onPageChange: EventEmitter<Paginator> =
    new EventEmitter<Paginator>();
  @Output() onTableConfigChange: EventEmitter<ColumnSettings> =
    new EventEmitter<ColumnSettings>();
  @Output() onSelectRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('scrollBelt', { read: ElementRef })
  scrollBelt: ElementRef<HTMLElement>;

  @HostListener('scroll', ['$event.target']) onScroll(e: HTMLElement) {
    let columnHeaderSettings = e.querySelector(
      '.column-header-settings-container'
    ) as HTMLElement;
    let maxScrollLeft = e.scrollWidth - e.clientWidth;
    columnHeaderSettings.style.right = -e.scrollLeft + 'px';
    this.scrollBelt.nativeElement.style.right = -e.scrollLeft + 'px';
    if (e.scrollLeft >= maxScrollLeft) {
      this.handleScrollBelt(false);
    } else {
      this.handleScrollBelt(true);
    }
  }

  columnsToDisplay: string[] = [];
  private headers: Header[] = [];
  private checkBoxDef = new CheckBoxColumnDef();
  private selectedRows: any[] = new Array<any>();
  protected headerCheck: boolean = false;

  constructor(private elRef: ElementRef, protected _context: TableContext) {}

  handleScrollBelt(show: boolean) {
    this.scrollBelt.nativeElement.style.display = show ? 'block' : 'none';
  }

  ngOnInit(): void {
    if (this.useTableContextFunction) {
      this._context.setPageSettings(
        this.settings.pageSize,
        0,
        this.data.length
      );
      this._context.setSortSettings(
        this.settings.sortBy,
        this.settings.sortDirection
      );
      this.sortData();
      this.loadPage();
    }
    this.reloadTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.handleLoader(false);
    }
  }
  ngAfterViewInit() {
    if (
      this.elRef.nativeElement.offsetWidth <
      this.elRef.nativeElement.scrollWidth
    ) {
      this.handleScrollBelt(true);
    }
  }

  public getColumnHeader(fieldName: string): Header {
    return this.headers.filter((x) => x.field === fieldName)[0];
  }
  getColumnDefinition(fieldName: string): Column {
    if (fieldName == this.checkBoxDef.fieldName) return this.checkBoxDef;
    return this.columnDefinitions.filter((x) => x.fieldName === fieldName)[0];
  }
  hasCustomButton(fieldName: string): boolean {
    return (
      this.headers.filter((x) => x.field === fieldName)[0].hasCustomButton ||
      false
    );
  }
  isCheckBoxColumn(fieldName: string): boolean {
    return fieldName == this.checkBoxDef.fieldName;
  }

  isCellToMark(fieldName: string, params: any) {
    if (this.columnsToDisplay.length > 0 && this.isTableMark) {
      let firstColumnName = this.columnsToDisplay[0];
      if (fieldName === firstColumnName) {
        if (this.isRowToMark != null && this.isRowToMark(params)) {
          return 'markRow';
        }
      }
    }
    return '';
  }

  public reloadTable() {
    this.columnsToDisplay = this.prepareColumsToDisplay();

    this.headers = this.prepareHeadersToDisplay();

    this.headers.forEach((x) => (x.hasCustomButton = false));
    this.headers[this.headers.length - 1].hasCustomButton = true;

    if (this.settings.sortBy !== '') {
      this.headers
        .filter((x) => x.field === this.settings.sortBy)
        .map((x) => {
          this.settings.sortDirection == 'desc'
            ? (x.sortType = SortType.Down)
            : (x.sortType = SortType.Up);
        });
    }
  }

  public reloadData(data: any[]) {
    this.data = [];
    this.data = data.slice();
  }

  private prepareColumsToDisplay() {
    let columns = this.columnDefinitions
      .filter((x) => !x.hidden && !x.allwaysHidden)
      .map((x) => x.fieldName);
    if (this.checkBoxs) return [this.checkBoxDef.fieldName].concat(columns);
    return columns;
  }

  private prepareHeadersToDisplay() {
    let headers = this.columnDefinitions
      .filter((x) => !x.hidden && !x.allwaysHidden)
      .map((x) => {
        return <Header>{
          sortable: x.sortable,
          header: x.header,
          field: x.fieldName,
          sortType: SortType.Default,
          type: x.type,
        };
      });

    if (this.checkBoxs) return [this.getCheckBoxHeader()].concat(headers);
    return headers;
  }

  sortChange(columnHeader: Header) {
    this.headers
      .filter((x) => x.field !== columnHeader.field)
      .map((x) => {
        x.sortType = SortType.Default;
      });

    this.handleLoader(true);
    if (this.useTableContextFunction) {
      this.onSortFunction({
        sortBy: columnHeader.field,
        sortDirection: this.sortDirection(columnHeader.sortType),
      });
    } else {
      this.onSortChange.emit({
        sortBy: columnHeader.field,
        sortDirection: this.sortDirection(columnHeader.sortType),
      });
    }
  }

  public handleLoader(show: boolean) {
    this.loading = show;
  }

  sortDirection(sortType: SortType): string {
    if (sortType === SortType.Up) return `asc`;

    return `desc`;
  }

  onChange(data: any) {
    let newPageSetting: Paginator = {
      totalCount: data.length,
      pageSize: data.pageSize,
      currentPage: data.pageIndex,
    };
    this.handleLoader(true);
    if (this.useTableContextFunction) {
      this.onPageChangeFunction(newPageSetting);
    } else this.onPageChange.emit(newPageSetting);
  }

  afterContentChange(event: any) {
    this.onDataChange.emit(this.data);
  }

  onChangeCheckBoxHeader(value: any) {
    this.headerCheck = value;
  }

  onChangeCheckBoxColumn(value: any) {
    let finded = this.selectedRows.find((data) => {
      return data == value.params;
    });
    if (value.value) {
      if (finded == undefined) {
        this.selectedRows.push(value.params);
      }
    } else {
      if (finded != undefined) {
        let index = this.selectedRows.indexOf(finded);
        this.selectedRows.splice(index, 1);
      }
    }
    this.onSelectRow.emit(this.selectedRows);
  }

  onSettingsChange(settings: ColumnSettings) {
    if (this.useTableContextFunction) {
      this.onTableConfigChangeFunction(settings);
    } else {
      this.settings.pageSize = settings.pageSize;
      this.onTableConfigChange.emit(settings);
    }
    this.reloadTable();
  }

  getSelectedRows() {
    return this.selectedRows;
  }

  private getCheckBoxHeader() {
    return {
      sortable: this.checkBoxDef.sortable,
      header: this.checkBoxDef.header,
      field: this.checkBoxDef.fieldName,
      sortType: SortType.Default,
      type: this.checkBoxDef.type,
    };
  }

  protected onSortFunction(sort: Sort) {
    this._context.setSortSettings(sort.sortBy, sort.sortDirection);
    this._context.setStartPage();
    this.sortData();
    this.loadPage();
    this.handleLoader(false);
  }

  protected onPageChangeFunction(page: Paginator) {
    this._context.setPageSettings(page.pageSize, page.currentPage);

    this.loadPage();
    this.handleLoader(false);
  }

  protected onTableConfigChangeFunction(settings: ColumnSettings) {
    this._context.setPageSettings(settings.pageSize, 0);
    this.loadPage();
  }

  private sortData() {
    let settings = this._context.pageSettings;
    let sortdata = this.data.slice().sort((n1, n2) => {
      if (
        settings.sortDirection === 'asc' &&
        n1[settings.sortBy] > n2[settings.sortBy]
      ) {
        return 1;
      }

      if (
        settings.sortDirection === 'desc' &&
        n1[settings.sortBy] > n2[settings.sortBy]
      ) {
        return -1;
      }

      if (
        settings.sortDirection === 'asc' &&
        n1[settings.sortBy] < n2[settings.sortBy]
      ) {
        return -1;
      }

      if (
        settings.sortDirection === 'desc' &&
        n1[settings.sortBy] < n2[settings.sortBy]
      ) {
        return 1;
      }

      return 0;
    });
    this.data = sortdata;
  }

  private loadPage() {
    let settings = this._context.pageSettings;
    let start = settings.pageSize * settings.pageNumber;
    let stop = start + settings.pageSize;
    if (stop > this.data.length) stop = this.data.length;

    this.dataDisplay = this.data.slice(start, stop);
  }
}
