import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import moment, { Moment } from 'moment';

const TOKEN_NAME: string = 'token';
const ID_NAME: string = 'id';
const USERNAME_NAME: string = 'username';
const TIMEZONE_NAME: string = 'timezone';
const SSO: string = 'sso';
const LOGIN_NAME: string = 'login';
const TIME: string = 'time';

@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService) {}
  saveDataInBrowser(data: any) {
    localStorage.setItem(TOKEN_NAME, data.token);
    localStorage.setItem(ID_NAME, data.id);
    localStorage.setItem(USERNAME_NAME, data.userName);
    localStorage.setItem(TIMEZONE_NAME, data.timezone);
  }
  saveLoginInBrowser(data: any) {
    if (localStorage.getItem(SSO) !== null) return;
    localStorage.setItem(
      SSO,
      JSON.stringify({ login: data.login, time: moment().toString() })
    );
  }
  removeLoginInBrowser() {
    localStorage.removeItem(SSO);
  }
  removeData() {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(ID_NAME);
    localStorage.removeItem(USERNAME_NAME);
    localStorage.removeItem(TIMEZONE_NAME);
  }

  getLoginFromBrowser() {
    let ssoLoginStr = localStorage.getItem(SSO);
    if (ssoLoginStr === null) return null;

    let sso: LoginSSO = JSON.parse(ssoLoginStr);
    return sso;
  }

  getToken(): string | null {
    return getToken();
  }
  isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.getToken() ?? '');
  }
  getUsername(): string | null {
    return localStorage.getItem(USERNAME_NAME);
  }
  getUserId(): string | null {
    return getUserId();
  }

  getTimeZone(): string | null {
    return getTimezone();
  }
}

export function getToken() {
  return localStorage.getItem(TOKEN_NAME);
}
export function getUserId() {
  return localStorage.getItem(ID_NAME);
}
export function getTimezone() {
  return localStorage.getItem(TIMEZONE_NAME);
}

export interface LoginSSO {
  login: string | null;
  time: Moment | null;
}
