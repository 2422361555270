import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import moment from 'moment';
import 'moment-timezone';
import { AuthService } from 'services/auth-service/auth.service';
@Injectable({
  providedIn: 'root',
})
export class CustomDateServiceService {
  private _date: Date;

  constructor(
    private readonly _dateAdapter: DateAdapter<any>,
    private authService: AuthService
  ) {}
  public setDate(date: Date) {
    this._date = date;
    return this;
  }
  get localeDateFormat() {
    let localeData = this._dateAdapter.parse(new Date(), '')?.localeData();
    return localeData?.longDateFormat('L');
  }
  get localeTimeFormat() {
    let localeData = this._dateAdapter.parse(new Date(), '')?.localeData();
    return localeData?.longDateFormat('LT');
  }
  get localeDateTimeFormat() {
    return `${this.localeDateFormat} ${this.localeTimeFormat}`;
  }
  public parseDateToMoment() {
    return this._dateAdapter.parse(this._date, '');
  }

  public toLocaleString() {
    return this.parseDateToMoment()?.format(this.localeDateTimeFormat);
  }

  public setLocale(locale: string) {
    this._dateAdapter.setLocale(locale || navigator.language);
  }

  public getDefaultTimeZone() {
    let timezone = this.authService.getTimeZone();

    if (timezone) return timezone;

    let zones = moment.tz.zonesForCountry(navigator.language);
    if (zones != null && zones.length > 0) return zones[0];
    return 'America/Los_Angeles';
  }

  public toLocaleStringWithTimezone(
    format: string | null = null,
    onlyDate = false
  ) {
    let timezone = this.getDefaultTimeZone();

    let mom = this.parseDateToMoment();
    if (mom !== null) {
      var date = moment(this.parseDateToMoment()).tz(timezone);
      if (!!format) {
        return date.format(format);
      } else {
        return date.format(
          onlyDate ? this.localeDateFormat : this.localeDateTimeFormat
        );
      }
    }
    return null;
  }
}
