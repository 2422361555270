<div class="file-upload-container">
  <app-upload-panel
    *ngIf="!readOnly"
    class="upload-panel"
    (uploadedFilesEvent)="uploadedFiles($event)"
  ></app-upload-panel>
  <mat-progress-bar
    *ngIf="loading && !readOnly"
    class="progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>
  <div *ngIf="!readOnly" class="info-section">
    Allowed File Types: .txt, .jpg, .png, .gif, .mp4, .pdf, .docx, .xlsx
    <br />
    File must be less than 3MB
  </div>
  <app-uploaded-files-panel
    class="file-list"
    [files]="files"
    [hideDelete]="readOnly"
    (downloadFileEvent)="downloadFile($event)"
    (deleteFileEvent)="deleteFile($event)"
  >
  </app-uploaded-files-panel>
</div>
