import { Injectable } from '@angular/core';

Injectable();
export class WindowService {
  public getWindow() {
    return window;
  }

  public getLocation() {
    return window.location;
  }

  public getSessionStorage() {
    return window.sessionStorage;
  }

  public getCreateObjectURL() {
    return URL.createObjectURL;
  }
}
